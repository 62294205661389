function Contact() {

  return (
    <section id='Contact' className="contact">
      <p>Contáctanos para hacer realidad tu proyecto</p>
      <div><a onClick={() => window.open('https://linktr.ee/compa.colombia', "_blank")} >Contacto</a></div>
    </section>
  );
}

export default Contact;
